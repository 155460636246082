import styled from "styled-components";

const SubtitleStyled = styled.section`
  margin-top: 20px;
  font-size: 28px;
  margin-bottom: 8px;
  .subtitle-line {
    height: 3px;
    width: 50px;
    background-color: ${({ theme }) => theme.COLORS.CONTRAST_COLOR};
  }
  @media screen and (min-width: 700px) {
    font-size: 30px;
    margin-bottom: 20px;
  }
`;

export default SubtitleStyled;
