export const theme = {
  ultrawide: "2000px",
  wide: "1600px",
  laptop: "1400px",
  tablet: "1130px",
  mobile: "600px",
  small_mobile: "400px",
  smaller_mobile: "300px",

  COLORS: {
    PRIMARY_TEXT: "#f2f2f2",
    SECONDARY_TEXT: "#141414",
    CONTRAST_COLOR: "red",
    GRADIENT_1: "",
    GRADIENT_2: "",
    PRIMARY_BG: "rgb(8, 8, 8)",
    SECONDARY_BG: "#141414",
  },
};

export const LOCATION_1 = "Gherla 405300,";
export const LOCATION_2 = "Strada Gradinarilor, nr 2A";
export const PHONE = "0735 864 401";
export const EMAIL = "automicar@yahoo.com";
export const SCHEDULE_TEXT = "Program de lucru";
export const SCHEDULE_PROGRAM = "Luni-Vineri 8:30 - 17:00";

export const ABOUT_TEXT_1 =
  "Suntem o echipă cu un angajament real, orientată spre comunicare optimă și dezvoltare continuă astfel încât să avem clienți mulțumiți. Cu o experienta de peste 10 ani, echipa AUTO MICAR pune accent pe profesionalism, transparenta si calitate. Personalul calificat si alegerea cu atentie a piselor auto folosite, permit efectuarea unor lucrari de reparatie profesionale la standarde de calitate ridicata.";
export const ABOUT_TEXT_2 =
  "Deasemenea, suntem un service auto autorizat RAR si agreat Mercedes-Benz, oferind documentare servicii in reteaua Mercedez-Benz. Astfel, nu pierzi istoricul autovehiculului tau si il ai la indemana in orice service sau reprezentanta Mercedes-Benz.";
export const ABOUT_SUBTITLE = "Dedicati calitatii";

export const GALLERY_SUBTITLE = "Galerie foto";

export const PROCESS_SUBTITLE = "Cum procedam?";

export const REVIEW_SUBTITLE = "Recenzii";

export const CONTACT_SUBTITLE_LONG =
  "Cere oferta de pret direct, selectand unul sau mai multe servicii de care ai nevoie si/sau lasa un mesaj text in care sa detaliezi nevoia ta, iar la final completeaza formularul cu datale de contact si cele ale autovehiculului.";

export const CHOOSE_SERVICES_SUBTITLE = "Alege servicii";

export const FILL_FORM_SUBTITLE = "Completeaza formularul";

export const MAP_LOCATION = "Locatia fizica";

export const GALLERY_IMAGES = [
  { id: 1, image: require("./1.jpg") },
  { id: 2, image: require("./2.jpg") },
  { id: 3, image: require("./1.jpg") },
  { id: 4, image: require("./2.jpg") },
  { id: 5, image: require("./1.jpg") },
  { id: 6, image: require("./2.jpg") },
];
