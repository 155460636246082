import React from "react";
import LinearColoredTextStyled from "./linearColoredTextStyled";

export default function LinearColoredText({ label }) {
  return (
    <LinearColoredTextStyled>
      <h3>{label}</h3>
    </LinearColoredTextStyled>
  );
}
