export const moreInfo = (function () {
  return (
    <article>
      <h3 style={{ margin: "20px 0" }}>
        Sfaturi utile pentru intretinerea corecta a masinii
      </h3>
      <p>
        Intretinerea masinii este un aspect extrem de important, daca vrei ca
        aceasta sa reziste in stare optima pentru cat mai mult timp. Indiferent
        de brand sau model, automobilul va performa in functie de cata atentie
        si cata grija ii vei acorda de-a lungul anilor.
      </p>
      <p>
        Fie ca tocmai ti-ai cumparat o masina noua sau vrei sa intretii cat mai
        bine pe cea pe care o ai deja de cativa ani, recomandarile de mai jos te
        vor ajuta enorm. Pune-le in aplicare si vei scuti atat timp, cat si
        bani, pastrand automobilul intr-o stare buna.
      </p>
      <h3 style={{ margin: "20px 0" }}>
        Iata 8 lucruri pe care sa le faci periodic, daca vrei ca masina ta sa
        functioneze si sa arate cat mai bine posibil!
      </h3>
      <h3 style={{ marginBottom: "20px" }}>1. Efectueaza reviziile la timp</h3>
      <p>
        Multi soferi se bazeaza pe faptul ca masina lor este relativ noua, asa
        ca nu are inca nevoie de revizie. adevarul este ca revizia e necesara
        ori de cate ori specifica manualul proprietarului, pe care l-ai primit
        odata cu automobilul. Daca nu gasesti acest manual, contacteaza dealerul
        auto sau cauta manualul online, pe site-ul producatorului. Odata ce
        verifici intervalul de timp, respecta-l si mergi cu masina la revizia
        tehnica periodica, pentru a te asigura ca totul este in regula. Nu
        dureaza mult, insa este o actiune care te poate feri de foarte multe
        probleme!
      </p>
      <h3 style={{ margin: "20px 0" }}>
        2. Mergi doar in service-uri autorizate
      </h3>
      <p>
        Cand automobilul tau intampina situatii ce necesita o vizita la service,
        este recomandat sa alegi intotdeauna un service autorizat. Astfel, vei
        sti ca angajatii sunt specialisti in adevaratul sens al cuvantului si
        ca-ti vor trata masina cu profesionalism. Evita service-urile auto
        obscure, care nu afiseaza lista cu preturi si care nu au autorizatiile
        vizibile. Chiar daca acestea iti pot promite tarife mai mici, nu vei
        avea niciodata garantia calitatii serviciilor si pieselor.
      </p>
      <h3 style={{ margin: "20px 0" }}>3. Spala masina regulat</h3>
      <p>
        Pare un sfat banal, insa pe care multi posesori de autoturisme il
        neglijeaza, crezand ca nu este atat de important. Ei, bine, spalarea
        frecventa a masinii nu tine doar de factorul estetic, ci mai ales de
        intretinerea ei pe termen lung. In timp, suprafata masinii tale este
        impregnata cu praf, ulei, substante chimice si ale reziduuri care pot
        ataca vopseaua. Totodata, daca nu inlaturi aceste reziduuri, particulele
        de praf, nisip sau pietris iti pot zgaria parbrizul, geamurile, capota
        sau portierele. Asadar, daca vrei ca automobilul tau sa fie intretinut
        in mod exemplar, fa-ti timp sa il duci la spalatorie cel putin o data pe
        saptamana sau, daca vei calatori in afara orasului, chiar mai des.
      </p>
      <h3 style={{ margin: "20px 0" }}>3. Spala masina regulat</h3>
      <p>
        Spalarea masinii este, de cele mai multe ori, generala si mai putin
        axata pe detalii. De aceea, o data pe an, este recomandat sa investesti
        intr-o sesiune de detailing. Aceasta presupune spalarea si curatarea in
        detaliu a automobilului, atat in exterior, cat mai ales in interior.
        Optand pentru un astfel de serviciu, te asiguri ca masina ta ramane in
        stare buna si isi pastreaza aspectul pe parcursul anilor.
      </p>
      <h3 style={{ margin: "20px 0" }}>
        5. Fii mereu atent la sunete sau miscari suspecte
      </h3>
      <p>
        Chiar daca nu esti specializat in mecanica, poti observa atunci cand
        ceva nu functioneaza asa cum ar trebui. De cele mai multe ori,
        neregulile se manifesta sub forma de sunete suspecte sau miscari pe care
        nu le-ai mai simtit pana in acel moment. In cazul in care auzi sunete
        ciudate sau observi ca masina ta se misca intr-un mod neobisnuit,
        contacteaza-ti imediat mecanicul. Acesta iti va oferi cateva sugestii
        utile, inainte sa ajungi cu automobilul in service.
      </p>
      <h3 style={{ margin: "20px 0" }}>
        6. Verifica frecvent presiunea in pneuri
      </h3>
      <p>
        Un alt aspect important pentru durata de viata si performanta masinii
        tale este presiunea din pneuri. aceasta trebuie sa fie intotdeauna
        echilibrata. In caz contrar, risti sa nu uzezi uniform cauciucurile sau
        sa faci pana atunci cand te astepti mai putin. Evita aceste neplaceri
        verificand presiunea macar o data la cateva saptamani.
      </p>

      <h3 style={{ margin: "20px 0" }}>
        7. Verifica toate consumabilele inainte sa pleci la drum
      </h3>
      <p>
        Daca urmeaza sa parcurgi o distanta mai mare cu automobilul, rezerva-ti
        15 minute pentru a verifica tot ce inseamna substante consumabile. Este
        vorba despre uleiul de motor, lichidul de parbriz, dar si despre
        lichidul de racire (antigel). Asigura-te ca uleiul se afla la nivelul
        optim, pentru a evita probleme ale motorului. Acestea sunt, de cele mai
        multe ori, dificil de rezolvat si foarte costisitoare.
      </p>
      <h3 style={{ margin: "20px 0" }}>
        8. Verifica sistemul de iluminat cel putin o data pe luna
      </h3>
      <p>
        Defectiunile la nivelul luminilor pot fi deosebit de periculoase, atat
        pentru tine, ca sofer, cat si pentru ceilalti participanti la trafic. In
        cazul in care luminile tale nu functioneaza, nu vei fi suficient de
        vizibil, iar probabilitatea de a fi implicat in accidente auto creste
        considerabil. Asadar, verifica frecvent farurile, stopurile, precum si
        proiectoarele. Daca observi ca unul dintre becuri nu mai functioneaza,
        schimba-l cat mai repede. Nu in ultimul rand, daca doresti sa iti
        intretii corect masina, condu preventiv si fii mereu atent la ce se
        intampla in trafic. Astfel, vei evita incidentele ce te pot afecta atat
        pe tine, cat si automobilul tau.
      </p>
    </article>
  );
})();
