import styled from "styled-components";

const TransparentBackgroundStyled = styled.article`
  width: 100%;
  height: 80%;
  position: absolute;
  bottom: 0;
  background-image: linear-gradient(
    to top,
    rgba(8, 8, 8, 1),
    rgba(8, 8, 8, 0.9),
    rgba(8, 8, 8, 0.1)
  );

  @media screen and (min-width: 1200px) {
    height: 60%;
  }
`;

export default TransparentBackgroundStyled;
