import styled from "styled-components";
import { Link } from "react-scroll";

export const MenuStyled = styled.ul`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => theme.tablet}) {
    min-width: auto;
    flex-direction: column;
    align-self: center;
    justify-content: space-evenly;
    height: 60%;
  }
  .dropdown {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .menuItem {
      margin-bottom: 25px;
    }
    .services-submenu {
      padding: 15px 10px;
      position: relative;
      color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
      border-radius: 15px;
      background-color: ${({ theme }) => theme.COLORS.PRIMARY_BG};
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: all 0.3s ease;
      opacity: 1;
      visibility: visible;
      top: 0;
      width: 180px;
      p {
        position: relative;
        margin: 4px;
        font-size: 15px;
        transition: 0.3s ease;
        &:hover {
          color: ${({ theme }) => theme.COLORS.CONTRAST_COLOR};
          transition: 0.3s ease;
        }
      }
    }
    .services-submenu-visible {
      opacity: 1;
      visibility: visible;
    }
  }

  @media screen and (min-width: 1131px) {
    .dropdown {
      .services-submenu {
        z-index: 1000;
        position: absolute;
        opacity: 0;
        visibility: hidden;
        top: 43px;
        width: 200px;
        p {
          font-size: 18px;
        }
      }
      .services-submenu-visible {
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;

export const MenuItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  position: relative;
  list-style: none;
  padding-top: 6px;
  margin: 0 15px;

  &:hover:after {
    left: 0;
    width: 100%;
    transition: 0.3s ease;
  }
  &::after {
    content: "";
    background-color: ${({ theme }) => theme.COLORS.CONTRAST_COLOR};
    position: absolute;
    width: 0;
    right: 0;
    bottom: -4px;
    height: 2px;
    transition: 0.3s ease;
  }
  .info-utile,
  .servicii {
    font-weight: 700;
    color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
    font-size: 22px;
    cursor: pointer;
  }
`;

export const MenuLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
  font-size: 20px;
  align-self: center;
  .services-arrow {
    position: relative;
    top: 5px;
    left: 3px;
  }

  @media screen and (max-width: ${({ theme }) => theme.tablet}) {
    font-size: 26px;
  }
`;
export const SubMenuLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
  font-size: 16px;
  align-self: center;
  @media screen and (max-width: ${({ theme }) => theme.tablet}) {
    font-size: 26px;
  }
`;

export const HamburgerMenuStyled = styled.div`
  width: 45%;
  max-width: 1000px;
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.tablet}) {
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
    outline: none;
    width: 45%;
  }
  .hamburgerStyle {
    width: 51px;
    height: 51px;
    -webkit-tap-highlight-color: transparent;
  }
`;
