import styled from "styled-components";

const LinearColoredTextStyledStyled = styled.article`
  h3 {
    font-size: 28px;
    letter-spacing: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    @media screen and (min-width: 350px) {
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      background-image: linear-gradient(
        to bottom,
        rgba(219, 219, 219, 1),
        rgba(219, 219, 219, 1),
        rgba(219, 219, 219, 0.5),
        rgba(219, 219, 219, 0.2),
        rgba(219, 219, 219, 0.1)
      );
      font-size: 40px;
    }
    @media screen and (min-width: 500px) {
      background-image: linear-gradient(
        to bottom,
        rgba(219, 219, 219, 1),
        rgba(219, 219, 219, 1),
        rgba(219, 219, 219, 0.5),
        rgba(219, 219, 219, 0.2),
        rgba(219, 219, 219, 0.1),
        rgba(219, 219, 219, 0),
        rgba(219, 219, 219, 0),
        rgba(219, 219, 219, 0),
        rgba(15, 15, 15, 0.1)
      );

      font-size: 60px;
    }
    @media screen and (min-width: 700px) {
      font-size: 100px;
    }
    @media screen and (min-width: 950px) {
      font-size: 150px;
    }
    @media screen and (min-width: 1235px) {
      margin-bottom: -10px;
      font-size: 200px;
    }
  }
`;
export default LinearColoredTextStyledStyled;
