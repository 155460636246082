import React, { useState } from "react";
import Header from "./components/header";
import Sections from "./sections";

import { ThemeProvider } from "styled-components";
import { theme } from "./constants/constants";

import Modal from "./components/modal/modal";
import "./index.css";

import { createContext } from "react";

export const ModalContext = createContext();

function App() {
  const [modalProps, setModalProps] = useState();
  const [isModalVisible, setIsModalVIsible] = useState(false);

  const updateModal = (obj) => {
    setModalProps(obj);
  };
  const handleModalVisibility = () => {
    setIsModalVIsible(!isModalVisible);
  };
  return (
    <>
      <ModalContext.Provider value={{ updateModal, handleModalVisibility }}>
        <ThemeProvider theme={theme}>
          <Modal isModalVisible={isModalVisible} modalProps={modalProps} />
          <Header />
          <Sections />
        </ThemeProvider>
      </ModalContext.Provider>
    </>
  );
}

export default App;
