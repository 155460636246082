import React, { useEffect } from "react";

import ModalStyled from "./modalStyled";

import { useContext } from "react";
import { ModalContext } from "../../App";

import { TfiClose } from "react-icons/tfi";

export default function Modal({ isModalVisible, modalProps }) {
  const { handleModalVisibility } = useContext(ModalContext);
  const modalText = document.querySelector(".modal-text");

  const blockBody = () => {
    document.body.style.height = "100vh";
    document.body.style.overflow = "hidden";
    modalText.scrollTop = 1;
  };
  useEffect(() => {
    isModalVisible ? blockBody() : (document.body.style.overflow = "initial");
  });
  return (
    <ModalStyled isModalVisible={isModalVisible} modalProps={modalProps}>
      <div className="modal-wrapper">
        <div className="modal-picture">
          <p className="modal-title">{modalProps?.title} </p>
        </div>

        <div className="modal-text">
          <div>{modalProps?.moreInfo}</div>
          <div className="credits">{modalProps?.creditsMoreInfo}</div>
        </div>
        <div className="close-modal" onClick={() => handleModalVisibility()}>
          <div className="closeButton">
            <TfiClose color="red" fontSize="20px" className="close-icon" />
          </div>
        </div>
      </div>
    </ModalStyled>
  );
}
