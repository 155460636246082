import React from "react";
import "./styling.scss";

export default function HederContact() {
  return (
    <div className="navbarContactStyle">
      <div className="navbar-phoneNumber">
        <p>Telefon: 0735 864 401</p>
      </div>
    </div>
  );
}
