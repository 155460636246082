import React from "react";

import ReviewStyled from "./reviewStyled";

import { REVIEW_DATA } from "./reviewData";

import Cards from "../../components/cards/cards";

import Subtitle from "../../components/subtitle/subtitle";

import { REVIEW_SUBTITLE } from "../../constants/constants";

import Footer from "../../components/footer/footer";

export default function Review() {
  return (
    <>
      <ReviewStyled>
        <div className="subtitle-wrapper">
          <Subtitle subtitle={REVIEW_SUBTITLE} />
        </div>
        <article className="review-wrapper">
          {REVIEW_DATA.map((review) => {
            return (
              <Cards
                key={review.id}
                image={review.image}
                title={review.title}
                content={review.content}
                alt="Acesta este un review"
                textDirection="center"
                width="100px"
                height="initial"
                borderRadius="0"
              />
            );
          })}
        </article>
      </ReviewStyled>
      <Footer />
    </>
  );
}
