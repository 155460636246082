import React from "react";

import ServiceStyled from "./serviceStyled";

import { useContext } from "react";
import { ModalContext } from "../../App";

export default function Service({
  content,
  image,
  button,
  title,
  moreInfo,
  service,
}) {
  const { updateModal, handleModalVisibility } = useContext(ModalContext);
  let visibility = service == 1 ? false : true;
  return (
    <ServiceStyled visible={visibility}>
      <img src={image} className="service-image" alt="reprezinta un serviciu" />
      <div className="service-text">
        <p className="service-title">{title}</p>
        <p>{content}</p>
        {button && (
          <button
            onClick={() =>
              (function () {
                updateModal({ title, image, content, moreInfo });
                handleModalVisibility();
              })()
            }
          >
            Afla mai mult
          </button>
        )}
      </div>
    </ServiceStyled>
  );
}
