import styled from "styled-components";

const ServicesStyled = styled.section`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_BG};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
`;

export default ServicesStyled;
