import styled from "styled-components";

export const ContactDetailsStyles = styled.article`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
  font-size: 16px;
  section {
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 20px;
  }

  .text {
    margin-left: 20px;
    line-height: 150%;
  }
  @media screen and (min-width: 1200px) {
    font-size: 18px;
    flex-direction: row;
    section {
      padding: 40px;
      border-bottom: ${({ borderBottom }) => borderBottom && "1px solid white"};
    }
    .bordered-section {
      border-right: ${({ marginBorders }) =>
        marginBorders && "1px solid white"};
      border-left: ${({ marginBorders }) => marginBorders && "1px solid white"};
    }
  }
  @media screen and (min-height: 1200px) {
    font-size: 30px;
    section {
      align-self: flex-start;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 57px 80px;
    }
    .text {
      margin-left: 20px;
      line-height: 40px;
    }
  }
`;
