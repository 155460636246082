import React from "react";

import SubtitleStyled from "./subtitleStyled";

export default function Subtitle({ subtitle }) {
  return (
    <SubtitleStyled>
      <h4>{subtitle}</h4>
      <div className="subtitle-line"></div>
    </SubtitleStyled>
  );
}
