import React from "react";

import { ContactDetailsStyles } from "./contactDetailsStyles";

import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineFieldTime } from "react-icons/ai";
import { RiContactsLine } from "react-icons/ri";

import {
  LOCATION_1,
  LOCATION_2,
  PHONE,
  EMAIL,
  SCHEDULE_TEXT,
  SCHEDULE_PROGRAM,
} from "../../constants/constants";

export default function ContactDetails({ marginBorders, borderBottom }) {
  return (
    <ContactDetailsStyles
      marginBorders={marginBorders}
      borderBottom={borderBottom}
    >
      <section>
        <div className="icon">
          <AiOutlineHome size="2.2em" />
        </div>
        <div className="text">
          <p>{LOCATION_1}</p>
          <p>{LOCATION_2}</p>
        </div>
      </section>
      <section className="bordered-section">
        <div className="icon">
          <RiContactsLine size="2.2em" />
        </div>
        <div className="text">
          <p>{PHONE}</p>
          <p>{EMAIL}</p>
        </div>
      </section>
      <section>
        <div className="icon">
          <AiOutlineFieldTime size="2.2em" />
        </div>
        <div className="text">
          <p>{SCHEDULE_TEXT}</p>
          <p>{SCHEDULE_PROGRAM}</p>
        </div>
      </section>
    </ContactDetailsStyles>
  );
}
