import React from "react";

import DownloadPdfButtonStyled from "./downloadPdfButtonStyled";

export default function DownloadPdfButton() {
  const onButtonClick = () => {
    fetch("Garantii.pdf").then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Garantii.pdf";
        alink.click();
      });
    });
  };
  return (
    <DownloadPdfButtonStyled onClick={onButtonClick}>
      Descarca garantia serviciilor noastre
    </DownloadPdfButtonStyled>
  );
}
