import React from "react";
import Home from "./home/home";
import About from "./about/about";
import Process from "./process/process";
import Services from "./services/services";
import Review from "./reviews/review";
import Contact from "./contact/contact";

import { Element } from "react-scroll";

export default function Sections() {
  return (
    <>
      <Element id="home" name="home">
        <Home />
      </Element>
      <Element id="about" name="about">
        <About />
      </Element>
      <Element id="services" name="service">
        <Services />
      </Element>
      <Element id="process" name="process">
        <Process />
      </Element>
      <Element id="contact" name="contact">
        <Contact />
      </Element>{" "}
      <Element id="reviews" name="review">
        <Review />
      </Element>
    </>
  );
}
