import styled from "styled-components";

export const NavbarStyled = styled.div`
  width: 75%;
  display: flex;
  justify-content: flex-end;
  max-width: 1800px;

  @media screen and (max-width: ${({ theme }) => theme.tablet}) {
    max-width: 100%;
    background-color: black;
    position: absolute;
    width: 100%;
    height: 100vh;
    justify-content: center;
    top: 0;
    transform: ${({ isOpen }) =>
      isOpen ? "translateY(0)" : "translateY(-100%)"};
    z-index: 200;
    transition: 0.6s ease;
  }
`;
