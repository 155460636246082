import styled from "styled-components";

export const StyledContact = styled.section`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_BG};
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
  .map-location-wrapper {
    position: relative;
    width: 100%;
    max-width: 1140px;
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
  }
  .footer-contact-details {
    margin-bottom: 30px;
    position: relative;
  }
  .contact-wrapper {
    position: relative;
    width: 85%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    .contact-subtitle-long {
      text-align: center;
      line-height: 150%;
      font-size: 18px;
      margin-bottom: 40px;
    }
    .contact-subtitle {
      padding-bottom: 120px;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    form .choose-services {
      position: relative;
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      label {
        font-size: 18px;
        margin-right: 20px;
        margin-bottom: 5px;
        margin-top: 10px;
      }
      input {
        margin: 0;
        margin-left: 10px;
      }
      .textarea-label {
        width: 100%;
        position: relative;
        p {
          position: absolute;
          top: 6px;
          padding: 0px 10px;
          left: 15px;
          z-index: 100;
          background-color: ${({ theme }) => theme.COLORS.PRIMARY_BG};
        }
      }
      .textarea {
        position: relative;
        border-radius: 10px;
        background-color: transparent;
        border: 1px solid white;
        color: white;
        outline: none;
        width: 107%;
        height: 150px;
        font-size: 18px;
        margin-top: 20px;
        padding: 20px;
      }
    }
    .legal-entity {
      align-self: flex-start;
      padding-top: 10px;
      h4 {
        margin: 20px 0 0 0;
      }
      .legal-entity-type {
        display: flex;
        flex-direction: column;

        div {
          label {
            font-size: 18px;

            input {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .contact-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 30px;
      width: 100%;
      .personal-contact,
      .car-contact {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .submit {
          display: flex;
          justify-content: center;
          flex-direction: column;
          span {
            margin-left: 10px;
            color: gray;
            opacity: 0.6;
          }
        }
        .input-box {
          position: relative;
          width: 100%;
          input {
            position: relative;
            width: 100%;
            border: 1px solid white;
            background-color: transparent;
            color: white;
            font-size: 18px;
            border-radius: 10px;
            margin: 20px 0;
            padding: 10px 15px;
            outline: none;
          }
          label {
            color: white;
            background-color: ${({ theme }) => theme.COLORS.PRIMARY_BG};
            left: 15px;
            top: 8px;
            padding: 0 10px 0 10px;
            position: absolute;
          }
          p {
            bottom: -8px;
            position: absolute;
            color: red;
            white-space: nowrap;
          }
        }
      }
      .car-contact {
        margin-right: 0;
      }
    }
    .send-button {
      align-self: center;
      position: relative;
      border: 1px solid red;
      background-color: transparent;
      padding: 10px 15px 5px;
      color: white;
      font-size: 18px;
      margin: 30px 0 40px;
      transition: 0.2s ease-out;
      letter-spacing: 2px;
    }
  }
  .map {
    margin: 10px 0;
    width: 100%;
    max-width: 1140px;
  }
  @media screen and (min-width: 500px) {
    .contact-wrapper {
      width: 90%;
      form .choose-services {
        label {
          margin-right: 60px;
        }
        .textarea-label {
          textarea {
            width: 400px;
          }
        }
      }
      .legal-entity {
        .legal-entity-type {
          div {
            input {
              margin-left: 10px;
            }
          }
        }
      }

      .contact-subtitle-long {
        text-align: initial;
      }
      .contact-form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
        width: 80%;
        .personal-contact,
        .car-contact {
          position: relative;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          .input-box {
            position: relative;
            width: 100%;
            input {
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 700px) {
    .map-location-wrapper {
      position: relative;
      width: 100%;
      max-width: 1140px;
      display: initial;
      justify-content: initial;
    }
    .footer-contact-details {
      margin-bottom: 130px;
      position: relative;
    }
    .contact-wrapper {
      form {
        .contact-form {
          flex-direction: row;
          width: 100%;
          justify-content: space-around;
          .personal-contact,
          .car-contact {
            position: relative;
            width: 45%;
            display: flex;
            justify-content: space-around;
          }
        }
      }
      .contact-subtitle-long {
        font-size: 22px;
        margin-bottom: 40px;
      }
      .contact-subtitle {
      }

      .send-button {
        margin-bottom: 10px;
        &:hover {
          cursor: pointer;
          transition: 0.3s ease-out;
          background-color: white;
          border: 1px solid white;
          color: red;
        }
      }
    }
    .map {
      width: 60%;
    }
  }
`;
