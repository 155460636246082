import React from "react";
import { HomeStyled } from "./homeStyled";
import ContactDetails from "../../components/contactDetails/contactDetails";
import TransparentBackground from "../../components/transparentBackground/transparentBackground";

import DownloadPdfButton from "../../components/downloadPdfButton/downloadPdfButton";

export default function Home() {
  return (
    <HomeStyled>
      <article className="homeTitles">
        <p>Atelier auto MULTIMARCA</p>
        <p className="middleText">
          Specializat <span>MERCEDES - BENZ</span>
        </p>
        <p>Servicii PROFESIONALE</p>
      </article>
      <ContactDetails marginBorders />

      <TransparentBackground />
      <article className="footerTextWrapper">
        <DownloadPdfButton />
        <article className="footerRotateWrapper">
          <div className="footerRotate"></div>
        </article>
      </article>
    </HomeStyled>
  );
}
