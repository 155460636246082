export const PROCESS_DATA = [
  {
    id: 1,
    image: require("./diagnoza.jpg"),
    title: "Constatare / Diagnoza",
    content: "Verificam si stabilim eventualele defectiuni",
  },
  {
    id: 2,
    image: require("./programare.jpg"),
    title: "Programare",
    content: "Stabilim impreuna parcursul reparatiilor",
  },
  {
    id: 3,
    image: require("./reparare.jpg"),
    title: "Reparare",
    content: "Ne ocupam cu seriozitate de toate detaliile",
  },
  {
    id: 4,
    image: require("./predare.jpg"),
    title: "Predare",
    content: "Oferim garantia unei executii de calitate",
  },
];
