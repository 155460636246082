import { LogoStyled } from "./logoStyled";
import logo from "./Logo_final.png";
import React from "react";

export default function Logo() {
  return (
    <>
      <LogoStyled>
        <img src={logo} alt="imaginea serviceului" />
      </LogoStyled>
    </>
  );
}
