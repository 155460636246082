import styled from "styled-components";

export const LogoStyled = styled.div`
  width: 15%;
  max-width: 1000px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 30px;
  color: rgba(90, 88, 88, 1);
  img {
    width: 200px;
  }
  @media screen and (max-width: ${({ theme }) => theme.tablet}) {
    font-size: 21px;
    width: 45%;
  }
`;
