import styled from "styled-components";

export const HeaderStyled = styled.article`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  .header-wrapper {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .nav-wrapper {
      position: relative;
      width: 100%;
      background-color: ${({ theme }) => theme.COLORS.PRIMARY_BG};
      display: flex;
      justify-content: center;
      align-items: center;

      .nav {
        background-color: ${({ theme }) => theme.COLORS.PRIMARY_BG};
        width: 100%;
        max-width: 2200px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
      }
    }
  }
`;
