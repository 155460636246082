import React from "react";

import ServicesStyled from "./servicesStyled";
import LinearColoredText from "../../components/linearColoredText/linearColoredText";
import Service from "../../components/service/service";

import { SERVICE_DATA } from "./serviceData";

import { Element } from "react-scroll";

import { FaRegHandshake } from "react-icons/fa";
import { MdElectricCar } from "react-icons/md";
import { AiTwotoneTool } from "react-icons/ai";
import { CgShoppingCart } from "react-icons/cg";
import { MdOutlineAutoFixHigh } from "react-icons/md";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { GiFrozenOrb } from "react-icons/gi";
import { GiAutoRepair } from "react-icons/gi";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

export default function Services() {
  const icons = [
    <FaRegHandshake />,
    <MdElectricCar />,
    <MdOutlineAutoFixHigh />,
    <GiAutoRepair />,
    <AiTwotoneTool />,
    <GiFrozenOrb />,
    <MdOutlineLibraryAdd />,
    <CgShoppingCart />,
  ];

  const linksToSubmenu = [
    "diagnoza",
    "intretinere",
    "verificare_generala",
    "mecanica",
    "instalatii",
    "retrofit",
    "piese",
  ];

  return (
    <ServicesStyled>
      <LinearColoredText label="SERVICII" />

      <VerticalTimeline>
        {SERVICE_DATA.map((service, index) => {
          return (
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "transparent",
                color: "#fff",
                boxShadow: "none",
              }}
              contentArrowStyle={{ borderRight: "7px solid white" }}
              iconStyle={{
                background: "black",
                color: "#fff",
              }}
              // date={service.title}
              icon={icons[index]}
              key={service.id}
              style={{ fontSize: 10 }}
            >
              <Element id={linksToSubmenu[index]} name={linksToSubmenu[index]}>
                <Service
                  content={service.content}
                  image={service.serviceImage}
                  button={service.button}
                  title={service.title}
                  moreInfo={service.moreInfo}
                  service={service.id}
                />
              </Element>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </ServicesStyled>
  );
}
