import React from "react";

import ProcessStyled from "./processStyled";
import Cards from "../../components/cards/cards";

import { PROCESS_SUBTITLE } from "../../constants/constants";

import { PROCESS_DATA } from "./processData";
import Subtitle from "../../components/subtitle/subtitle";

import { FaCarCrash } from "react-icons/fa";
import { IoCarSport } from "react-icons/io5";
import { FcCheckmark } from "react-icons/fc";
import { MdClose } from "react-icons/md";
import road from "./road.png";

export default function Process() {
  return (
    <ProcessStyled>
      <div className="subtitle-wrapper">
        <Subtitle subtitle={PROCESS_SUBTITLE} />
      </div>
      <article className="process-wrapper">
        <FaCarCrash size="4.2rem" className="left-car" />
        <IoCarSport size="4.2rem" className="right-car" />
        <img src={road} alt="acesta este un drum" className="road" />
        <FcCheckmark size="2.3rem" className="car-good" />
        <MdClose size="2.3rem" className="car-damaged" color="red" />
        {PROCESS_DATA.map((process, index) => {
          return (
            <Cards
              key={process.id}
              image={process.image}
              title={process.title}
              content={process.content}
              alt="Acesta este un proces"
              textDirection="center"
              width="220px"
              height="240px"
              borderRadius="50%"
              polygon
              shadow
              top={index % 2 === 1 && true}
            />
          );
        })}
      </article>
    </ProcessStyled>
  );
}
