import styled from "styled-components";

const DownloadPdfButtonStyled = styled.div`
  position: relative;
  text-align: center;
  font-size: 14px;
  letter-spacing: 1px;
  z-index: 100;
  &:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 1200px) {
    font-size: 16px;
  }
`;

export default DownloadPdfButtonStyled;
