import React, { useRef, useState } from "react";

import emailjs from "@emailjs/browser";

import { StyledContact } from "./contactStyled";
import LinearColoredText from "../../components/linearColoredText/linearColoredText";

import Subtitle from "../../components/subtitle/subtitle";
import ContactDetails from "../../components/contactDetails/contactDetails";

import {
  ValidateValueLength,
  ValidateUserEmail,
  ValidateUserPhone,
} from "../../validations/userValidations";

import {
  CHOOSE_SERVICES_SUBTITLE,
  FILL_FORM_SUBTITLE,
  CONTACT_SUBTITLE_LONG,
  MAP_LOCATION,
} from "../../constants/constants";

export default function Contact() {
  const [checkValue, setCheckValue] = useState("Persoana fizica");
  const [firstName, setFirstName] = useState("Nume");
  const [lastName, setLastName] = useState("Prenume");

  const [vim, setVim] = useState("");
  const [showVim, setShowVim] = useState(false);
  const [email, setEmail] = useState("");
  const [showEmail, setShowEmail] = useState(false);
  const [phone, setPhone] = useState("");
  const [showPhone, setShowPhone] = useState(false);
  const [disabledValue, setDisabledValue] = useState(false);
  const [message, setMessage] = useState("");
  const [DataProcessing, setDataProcessing] = useState(false);

  const changeLabel = (e) => {
    setCheckValue(e.target.value);
    setFirstName(checkValue === "Persoana fizica" ? "Denumire firma" : "Nume");
    setLastName(checkValue === "Persoana fizica" ? "CUI" : "Prenume");
  };

  const [buttonValue, setButtonValue] = useState("TRIMITE");
  const [validations, setValidations] = useState(false);

  const buttonsCheck = () => {
    const buttons = document.querySelectorAll(".choose-services label input");
    for (let btn of buttons) {
      if (btn.checked) {
        return true;
      }
    }
  };

  const checkValidations = () => {
    console.log(DataProcessing);
    if (
      !showVim &&
      DataProcessing &&
      !showEmail &&
      !showPhone &&
      vim !== "" &&
      email !== "" &&
      phone !== "" &&
      (message !== "" || buttonsCheck())
    ) {
      setValidations(true);
    } else {
      alert(
        "Pentru a va putea oferi servicii de calitate, este necesara completarea coracta a tuturor datelor si scrierii unui mesaj sau alegerii unui serviciu. Multumim"
      );
      setValidations(false);
    }
  };

  const AcceptDataProcessing = () => {
    setDataProcessing(!DataProcessing);
  };
  const ValidateVim = () => {
    setShowVim(ValidateValueLength({ vim }));
  };
  const ValidateEmail = () => {
    setShowEmail(ValidateUserEmail({ email }));
  };
  const ValidatePhone = () => {
    setShowPhone(ValidateUserPhone({ phone }));
  };
  const focusVim = () => {
    setShowVim(false);
  };
  const focusEmail = () => {
    setShowEmail(false);
  };
  const focusPhone = () => {
    setShowPhone(false);
  };
  const form = useRef();

  const resetForm = () => {
    form.current?.reset();
  };

  const submitFunctions = () => {
    resetForm();
    setPhone("");
    setEmail("");
    setVim("");
    setMessage("");
    AcceptDataProcessing();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setButtonValue("Mesaj in curs de trimitere");
    setDisabledValue(true);
    emailjs
      .sendForm(
        "service_qfcopfk",
        "template_6ai953d",
        form.current,
        "0gAhEKFkzn8XRbu9D"
      )
      .then(
        (result) => {
          setDisabledValue(false);
          setButtonValue("TRIMITE");
          submitFunctions();
          alert(
            "Mesajul a fost trimis cu succes. Va multumim si va vom contacta in cel mai scurt timp posibil."
          );
        },
        (error) => {
          "Ne pare rau, insa a avut loc o eroare la procesare. Va rugam mai incercati o data, sau contactati-ne la numarul de telefon disponibili sau scrieti-ne un e-mail. Va multumim pentru intelegere";
        }
      );
  };
  return (
    <>
      <StyledContact>
        <LinearColoredText label="CONTACT" />
        <div className="footer-contact-details">
          <ContactDetails borderBottom />
        </div>

        <article className="contact-wrapper">
          <p className="contact-subtitle-long">{CONTACT_SUBTITLE_LONG}</p>
          <Subtitle
            className="contact-subtitle"
            subtitle={CHOOSE_SERVICES_SUBTITLE}
          />

          <form ref={form} onSubmit={sendEmail}>
            <div className="choose-services">
              <label>
                Montaj echipamente electronice
                <input
                  type="checkbox"
                  name="Montaj echipamente electronice"
                  value="Montaj echipamente electronice"
                />
              </label>
              <label>
                Testare electrica
                <input
                  type="checkbox"
                  name="Testare electrica"
                  value="Testare electrica"
                />
              </label>
              <label>
                Verificare de sezon
                <input
                  type="checkbox"
                  name="Verificare de sezon"
                  value="Verificare de sezon"
                />
              </label>
              <label>
                Verificare de cumparare
                <input
                  type="checkbox"
                  name="Verificare de cumparare"
                  value="Verificare de cumparare"
                />
              </label>
              <label>
                Revizie periodica
                <input
                  type="checkbox"
                  name="Revizie periodica"
                  value="Revizie periodica"
                />
              </label>
              <label>
                Verificare instalatii AC
                <input
                  type="checkbox"
                  name="Verificare instalatii AC"
                  value="Verificare instalatii AC"
                />
              </label>
              <label>
                Verificare generala
                <input
                  type="checkbox"
                  name="Verificare generala"
                  value="Verificare generala"
                />
              </label>
              <label className="textarea-label">
                <p>Lasa un mesaj</p>
                <textarea
                  className="textarea"
                  name="message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </label>
            </div>
            <div className="legal-entity">
              <Subtitle subtitle={FILL_FORM_SUBTITLE} />
              <h4>Forma juridica</h4>
              <div className="legal-entity-type">
                <div>
                  <label>Persoana fizica</label>
                  <input
                    type="radio"
                    name="Statut juridic"
                    id="fizica"
                    value="Persoana fizica"
                    checked={checkValue === "Persoana fizica"}
                    onChange={changeLabel}
                  />
                </div>
                <div>
                  <label>Persoana juridica</label>
                  <input
                    type="radio"
                    name="Statut juridic"
                    id="juridica"
                    value="Persoana juridica"
                    checked={checkValue === "Persoana juridica"}
                    onChange={changeLabel}
                  />
                </div>
              </div>
            </div>
            <section className="contact-form">
              <div className="personal-contact">
                <div className="input-box">
                  <input type="text" name="firstName" />
                  <label>{firstName}</label>
                </div>
                <div className="input-box">
                  <input type="text" name="lastName" />
                  <label>{lastName}</label>
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    name="phone"
                    onBlur={ValidatePhone}
                    onFocus={focusPhone}
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                  <label>Telefon</label>
                  {showPhone && <p>Numarul trebuie sa contina doar cifre</p>}
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    name="email"
                    onBlur={ValidateEmail}
                    onFocus={focusEmail}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <label>Email</label>
                  {showEmail && <p>Formatul mailului este gresit</p>}
                </div>
              </div>
              <div className="car-contact">
                <div className="input-box">
                  <input type="text" name="brand" />
                  <label> Marca autoturism</label>
                </div>
                <div className="input-box">
                  <input type="text" name="model" />
                  <label>Model</label>
                </div>
                <div className="input-box">
                  <input
                    type="text"
                    name="VIM"
                    value={vim}
                    onChange={(e) => setVim(e.target.value)}
                    onBlur={ValidateVim}
                    onFocus={focusVim}
                  />
                  <label> Serie sasiu (VIN)</label>
                  {showVim && <p>Seria trebuie sa cotina 17 caractere</p>}
                </div>
                <div className="submit">
                  <input
                    type={(validations && "submit") || "button"}
                    value={buttonValue}
                    className="send-button"
                    onClick={checkValidations}
                    disabled={disabledValue}
                  />
                  <label>
                    <input
                      type="checkbox"
                      name="AcordProcesareDate"
                      value="Da"
                      onClick={AcceptDataProcessing}
                    />
                    <span>Sunt de acord cu proceasarea datelor</span>
                  </label>
                </div>
              </div>
            </section>
          </form>
        </article>

        <div className="map">
          <div className="map-location-wrapper">
            <Subtitle subtitle={MAP_LOCATION} />
          </div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2719.788136094269!2d23.893874851295553!3d47.0247635358793!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4749bd8001c0e2a7%3A0x401f77b669a24d09!2sAuto%20Micar!5e0!3m2!1sro!2sro!4v1669573678416!5m2!1sro!2sro"
            style={{ border: 0 }}
            width="100%"
            height="500px"
            allowFullScreen=""
            loading="lazy"
            aria-hidden="false"
            tabIndex="0"
            referrerPolicy="no-referrer-when-downgrade"
            title="map"
          ></iframe>
        </div>
      </StyledContact>
    </>
  );
}
