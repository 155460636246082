import styled from "styled-components";

const StyledFooter = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(90, 88, 88, 1);
  color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
  background-color: black;
  color: white;
  font-size: 17px;

  .footerWrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    .footer-image img {
      display: none;
    }
    .footer-text {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      right: initial;
      margin: 30px 0;
      .policies {
        display: flex;
        justify-content: space-evenly;
        border-bottom: 1px solid white;
        padding-bottom: 10px;
        margin-bottom: 12px;
        flex-direction: column;
        align-items: center;
        span {
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .footer-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      padding-bottom: 50px;
      img {
        padding: 5px;
      }
    }
    .development {
      position: absolute;
      bottom: 0;
      margin-bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 13px;
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .footerWrapper {
      flex-direction: row;

      .footer-image img {
        display: block;
        width: 500px;
      }
      .footer-text {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        right: 150px;
        .policies {
          width: 400px;
          flex-direction: row;
        }
      }
      .footer-icons {
        padding-bottom: 20px;
      }
    }
  }
`;

export default StyledFooter;
