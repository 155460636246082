import styled from "styled-components";

const CardsStyled = styled.section`
  position: relative;
  color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};

  .card-wrapper {
    position: relative;
    width: 260px;
    text-align: ${(props) => props.textDirection};
    margin: 25px 0;
    .image-wrapper {
      filter: ${(props) => props.shadow && "drop-shadow(5px 1px 1px red)"};
    }
    .card-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: ${(props) => props.width};
      height: ${(props) => props.height};
      clip-path: ${(props) =>
        props.polygon &&
        "polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%)"};
    }
    h3 {
      margin-top: 10px;
      font-size: 22px;
    }
    p {
      font-size: 18px;
      line-height: 22px;
    }

    @media screen and (min-width: 500px) {
      margin: 25px 30px;
    }
    @media screen and (min-width: 1200px) {
      top: ${(props) => props.top === true && "50px"};
    }
  }
`;

export default CardsStyled;
