export const SERVICE_DATA = [
  {
    id: 1,
    serviceImage: require("./primire.png"),
  },
  {
    id: 2,
    serviceImage: require("./diagnoza2.jpg"),
    title: "Electrica auto / Diagnoza computerizata",
    content:
      "Defectiunile de natura electrica, pot fi foarte deranjante, punand in acelasi timp in pericol siguranta soferului, a pasagerilor, dar si a celorlalti participanti din trafic.",
    moreInfo: (function () {
      return (
        <article>
          <h3 style={{ margin: "20px 0" }}>
            TIPURI DE INTERVENTII PE CARE LE PUTEM REALIZA:
          </h3>
          <p> </p>
          <p>Repararea echipamentului electric si electronic</p>
          <p>Reglarea echipamentului electric si electronic</p>
          <p>
            Receptii interactive (diagnoza rapida a problemelor tehnice ale
            masinii)
          </p>
          <p>Diagnoza completa</p>
          <p>Codari calculatoare</p>
          <p>Reparatii alternatoare</p>
          <p>Verificare acumulatori</p>
          <p>Verificare suspensie pneumatica</p>
          <p>Regenerare filtru de particule</p>
          <p>Activare functii calculatoare</p>
          <p>Reparatii alternatoare ++++</p>

          <p style={{ margin: "20px 0" }}>
            Verificari de parametri - daca nu esti sigur ca autovehiculul tau
            functioneaza in parametri optimi, adu-l la noi sa ii facem o
            verificare. Vom testa parametrii alternatorului, ai electromotorului
            si al intregului sistem electric. Diagnoza specializata - folosim un
            sistem de diagnoza completa , care este capabil sa identifice pana
            si cele mai ascunse defectiuni ale sistemului electric si nu numai.
          </p>

          <p>
            Verificari acumulatori - ne vom asigura ca parametrii functionali ai
            acumulatorului autoturismului tau sunt optimi; in acest fel vei sti
            sigur cand va trebui sa inlocuiesti acumulatorul, ce durata de viata
            estimata mai are si cat timp te mai poti baza pe acesta. Masina ta
            nu va sta in service mai mult decat e nevoie si astfel te vei putea
            servi de ea cat de repede posibil. Oferim profesionalism, reparatii
            rapide, piese de schimb originale dar si preturi speciale la
            achizitia acestora, pentru clientii nostri.
          </p>

          <h3 style={{ margin: "20px 0" }}>
            Apeleaza la un service autorizat. Expertii nostri iti stau la
            dispozitie!
          </h3>

          <p style={{ margin: "20px 0" }}>
            Ne gasesti la numerele de telefon 0735864401 / 0763955345 sau te
            asteptam la punctul nostru de lucru Gherla Str.Gradinarilor Nr 2A
            Jud.Cluj
          </p>
        </article>
      );
    })(),
    button: true,
  },
  {
    id: 3,
    serviceImage: require("./intretinere.jpeg"),
    title: "Intretinere",
    content:
      "Intretinerea periodica a masinii este necesara si vitala. Chiar daca la prima vedere este o bataie de cap, vizita regulata la un service auto nu este optionala.",
    moreInfo: (function () {
      return (
        <article>
          <p>
            O intretinere proactiva a masinii va usura viata oricarui
            proprietar, iar urmatoarele sfaturi au rolul informativ de a
            clarifica orice nelamurire cu privire la intretinerea periodica.
            Iata interventiile standard valabile pentru majoritatea
            autoturismelor care ar trebui efectuate:
          </p>

          <h3 style={{ margin: "20px 0" }}>
            Schimbarea frecventa a uleiului motorului
          </h3>

          <p>
            Uleiul este cel care „da viata” motorului pentru un motiv destul de
            evident. Lubrifierea corespunzatoare inseamna un motor sanatos, prin
            urmare, inlocuirea uleiului trebuie facuta periodic. Filtrul si
            uleiul de motor ar trebui schimbate la fiecare 5000 - 8000 km in
            cazul masinilor care ruleaza pe benzina. In cazul uleiurilor
            sintetice de ultima generatie, schimbarea ar trebui Intretinerea
            periodica a autoturismuluifacuta la fiecare 12000 km. Pentru ca
            majoritatea calatoriilor facute cu masina sunt scurte si in conditii
            grele de trafic, este indicata schimbarea mai frecventa a uleiului.
          </p>

          <h3 style={{ margin: "20px 0" }}>
            Prima verificare importanta a masinii la 25.000 km
          </h3>

          <p>
            Prima vizita la un service auto ar trebui facuta la aproximativ
            25000 km sau la un an de la achizitionarea masinii. Avand in vedere
            ca autoturismul este aproape nou, aceasta ar trebui sa fie o
            procedura standard. La prima verificare trebuie schimbat filtrul de
            ulei, uleiul de motor si filtrele de aer (de cabina si de motor).
            Schimbarea filtrului de aer al motorului are rolul de a asigura
            consumul optim de combustibil. Un nou filtru de aer de cabina retine
            praful si contaminantii de interiorul masinii. Verificarea lamelor
            stergatoarelor de parbriz este importanta si nu trebuie ignorata.
          </p>

          <h3 style={{ margin: "20px 0" }}>Verificarea la 50.000 km</h3>

          <p>
            In plus fata de componentele care trebuie inlocuite si in cazul
            verificarii la 25 000 km, filtrul de combustibil trebuie verificat
            si inlocuit daca este cazul. La aceasta verificare trebuie schimbat
            si lichidul de racire a motorului pentru a evita supraincalzirea.
            Inlocuirea lichidului de servodirectie si de transmisie este
            importanta pentru ca acestea isi pied eficienta cu timpul. Aceste
            interventii ar trebui repetate la fiecare 50 000 km.
          </p>

          <h3 style={{ margin: "20px 0" }}>Verificarea la 75.000 km</h3>

          <p>
            Atentia ar trebui indreptata catre sistemul de aprindere al
            autoturismului. Inlocuirea bujiilor trebuie realizata la cel mult 75
            000 km sau la fiecare 3 ani. Bujiile uzate pot afecta durata de
            viata a motorului, dar si performanta masinii. Instalarea unui nou
            sistem de gestionare a aprinderii este necesara pentru un control
            eficient al bujiilor si o functionare normala a sistemului de
            aprindere.Daca nu este schimbat la fiecare 3 ani sau la 75 000 km,
            lichidul de frana se ingroasa si isi pierde din eficacitate.
            Verificarea placutelor de frana si inlocuirea acestora este la fel
            de importanta.
          </p>

          <h3 style={{ margin: "20px 0" }}>Verificarea la 100.000 km</h3>

          <p>
            La acest kilometraj cureaua de distributie trebuie inlocuita, iar
            daca acest lucru este ignorat, exista pericolul ca anumite
            componente interne ale masinii sa fie compromise, inclusiv afectarea
            grava a motorului.Bateria masinii nu trebuie neglijata, deoarece
            poate cauza probleme atunci cand incercati sa porniti motorul.
            Oricat de tentant ar fi, intretinerea periodica a componentelor si
            inlocuirea lor daca este cazul, nu trebuie ignorata!
          </p>

          <p style={{ margin: "20px 0" }}>
            Apeleaza la un service autorizat. Expertii nostri iti stau la
            dispozitie!
          </p>
        </article>
      );
    })(),
    button: true,
  },
  {
    id: 4,
    serviceImage: require("./mecanica.jpeg"),
    title: "Mecanica auto",
    content:
      "Partea de mecanica prezinta o importanta deosebita pentru functionarea corecta a autoturismului dumneavoastra. Va punem la dispozitie diverse tipuri de interventii mecanice.",
    moreInfo: (function () {
      return (
        <article>
          <h3 style={{ margin: "20px 0" }}>Interventii mecanice</h3>
          <p>Verificarea sistemului de franare</p>
          <p>Schimb de ulei, filtre si fluide de racire</p>
          <p>
            Schimb de piese la sistemul de franare: placute, discuri, saboti,
            cilindri, cabluri, etriere
          </p>
          <p>
            Inlocuire elemente de directie: bielete, caseta de directie, capete
            de bara, uleiuri caseta de directie, pompa servo, rulmenti,
            articulatii
          </p>
          <p>
            Schimb elemente de suspensie - amortizoare, arcuri, flanse, rulmenti
            de sarcina, brate suspensie si antiruliu, pivoti, bucse.
          </p>
          <p>Reparare si intretinere a turbosuflantelor auto</p>
          <p>
            Verificarea compresiei - curatarea sau inlocuirea galeriilor de
            admisie si a componentelor aferente acestora
          </p>
          <p>Inlocuire distributie</p>
          <p>Schimb, inlocuire si reparare a elementelor motorului masinii</p>
          <p style={{ margin: "20px 0" }}>
            Pentru orice operatiune realizata folosim doar piese de schimb
            originale, de cea mai buna calitate, pentru ca autoturismul
            dumneavoastra sa fie pus din nou in functiune in cele mai bune
            conditii. Profesionalismul mecanicilor nostri va garanteaza cele mai
            eficiente solutii, in asa fel incat autoturismul sa poata fi din nou
            utilizat in cel mai scurt timp posibil si in deplina siguranta.
          </p>
          <p></p>
        </article>
      );
    })(),
    button: true,
  },
  {
    id: 5,
    serviceImage: require("./clima.jpeg"),
    title: "Servicii  aer conditionat / Clima",
    content:
      "Oricine cunoaste importanta instalatiei AC, in special in zilele caniculare. Instalatia poate uneori sa nu mai functioneze corespunzator.",
    moreInfo: (function () {
      return (
        <article>
          <h3 style={{ margin: "20px 0" }}>
            Pentru a va asigura de deplina functionalitate a instalatiei AC
            instalata pe masina dumneavoastra, va oferim urmatoarele servicii:
          </h3>
          <p>
            Diagnosticare, evaluare si reparare instalatii automate de
            climatizare
          </p>
          <p style={{ margin: "20px 0" }}>
            Verificare parametri functionali instalatii AC
          </p>
          <p>Igienizare instalatii AC</p>
          <p style={{ margin: "20px 0" }}>Revizie generala instalatii AC</p>
          <p>Golire si reumplere instalatii automate de climatizare</p>
          <p style={{ margin: "20px 0" }}>
            Inlocuire ulei, filtre, dezumidificatoare
          </p>
          <p>
            Inlocuire componente instalatii AC: radiator AC, electroventilator
            AC, furtune si conducte
          </p>
          <h3 style={{ margin: "20px 0" }}>
            Apeleaza la un service autorizat. Expertii nostri iti stau la
            dispozitie!
          </h3>
        </article>
      );
    })(),
    button: true,
  },
  {
    id: 6,
    serviceImage: require("./retrofit.jpeg"),
    title: "Retrofit",
    content:
      "Retrofittingul se refera la adaugarea sau modernizare  de noi tehnologii sau caracteristici la sistemele mai vechi.",
    moreInfo: (function () {
      return (
        <article>
          <h3>
            Exemple de retrofitting: montaj carlig remocare, camere
            video,senzori parcare, android auto se exemplele pot continua.
          </h3>
          <p style={{ margin: "20px 0" }}>
            Apeleaza la un service autorizat. Expertii nostri iti stau la
            dispozitie!
          </p>
          <p>
            Ne gasesti la numerele de telefon 0735864401 / 0763955345 sau va
            asteptam la punctul nostru de lucru din Gherla Str.Gradinarilor 2A
            Jud.Cluj
          </p>
        </article>
      );
    })(),
    button: true,
  },
  {
    id: 7,
    serviceImage: require("./piese.jpeg"),
    title: "Piese auto",
    content:
      "Comercializam o gama larga de piese auto de origine sau aftermarket.",
    moreInfo: "",
  },
];
