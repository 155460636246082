import styled from "styled-components";

export const AboutStyled = styled.section`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 80px);
  background-image: linear-gradient(
    to top,
    ${({ theme }) => theme.COLORS.PRIMARY_BG},
    black,
    black,
    black,
    ${({ theme }) => theme.COLORS.PRIMARY_BG},
    ${({ theme }) => theme.COLORS.PRIMARY_BG}
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
  .text-wrapper {
    width: 70%;
    max-width: 900px;
    margin-bottom: 45px;
  }
  .about-text,
  .about-text_2 {
    font-size: 1em;
  }
  .about-text_2 {
    margin-top: 20px;
  }
  .about-image {
    margin-bottom: 5%;
    width: 90%;
    max-width: 1500px;
  }
  .image-gallery {
    position: relative;
    width: 95%;
    max-width: 1200px;
    min-width: none;
    margin-bottom: 30px;

    .main-image {
      /* border-radius: 10px; */
      width: 100%;
      height: 250px;
      background-image: url(${(props) => props.imageSource});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 10px 0;
    }
    .gallery-images {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      img {
        width: 90px;
        margin-bottom: 5px;
        /* border-radius: 7px; */
      }
    }
  }
  @media screen and (min-width: 700px) {
    .about-text {
      font-size: 1.3em;
    }
    .text-wrapper {
      margin-bottom: 100px;
      width: 70%;
    }

    .image-gallery {
      position: relative;
      width: 80%;
      max-width: 1200px;
      min-width: none;
      margin-bottom: 50px;
      .main-image {
        height: 450px;
      }
      .gallery-images {
        display: flex;
        justify-content: space-between;
        align-items: center;
        img {
          width: 15%;
          /* border-radius: 7px; */
        }
      }
    }
  }
  @media screen and (min-width: 1100px) {
    .image-gallery {
      max-width: 1200px;
      min-width: 1000px;
      .main-image {
        height: 600px;
      }
    }
  }
`;
