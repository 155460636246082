import React from "react";

import CardsStyled from "./cardsStyled";

export default function Cards({
  image,
  title,
  content,
  alt,
  textDirection,
  width,
  height,
  borderRadius,
  polygon,
  shadow,
  top,
}) {
  return (
    <CardsStyled
      textDirection={textDirection}
      width={width}
      height={height}
      borderRadius={borderRadius}
      polygon={polygon}
      shadow={shadow}
      top={top}
    >
      <div className="card-wrapper">
        <div className="image-wrapper">
          <img src={image} alt={alt} className="card-image" />
        </div>

        <h3>{title}</h3>
        <p>{content}</p>
      </div>
    </CardsStyled>
  );
}
