import React, { useState } from "react";
import { MenuStyled, MenuItem, MenuLink, SubMenuLink } from "./menuStyled";
import { BrowserRouter as Router } from "react-router-dom";

import infoImage from "./info.jpg";

import { useContext } from "react";
import { ModalContext } from "../../../App";

import { IoIosArrowDown } from "react-icons/io";

import { moreInfo } from "./InfoData";

export default function Menu({ openMenu }) {
  const { updateModal, handleModalVisibility } = useContext(ModalContext);

  const [submenuHovered, setSubmenuHovered] = useState(false);
  const [isServiceHovered, setIsServiceHovered] = useState(false);

  const handleEnterSubmenu = () => {
    setSubmenuHovered(true);
  };
  const handleLeaveSubmenu = () => {
    setSubmenuHovered(false);
  };

  const handleEnterService = () => {
    setIsServiceHovered(true);
  };
  const handleLeaveService = () => {
    setIsServiceHovered(false);
  };

  return (
    <>
      <MenuStyled>
        <Router>
          <MenuItem>
            <MenuLink
              to="home"
              spy={true}
              offset={-70}
              smooth={false}
              duration={500}
              onClick={() => openMenu()}
            >
              Acasa
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink
              spy={true}
              offset={-70}
              smooth={true}
              duration={500}
              to="about"
              onClick={() => openMenu()}
            >
              Despre noi
            </MenuLink>
          </MenuItem>

          <MenuItem
            className="menuItem dropdown"
            onMouseEnter={() => handleEnterService()}
            onMouseLeave={() => handleLeaveService()}
          >
            <MenuLink
              spy={true}
              offset={-70}
              smooth={true}
              duration={500}
              to="services"
              onClick={() => openMenu()}
              className="servicii"
            >
              <p>
                Servicii
                <span className="services-arrow">
                  <IoIosArrowDown />
                </span>
              </p>
            </MenuLink>
            <div
              onMouseEnter={() => handleEnterSubmenu()}
              onMouseLeave={() => handleLeaveSubmenu()}
              className={`services-submenu  ${
                (submenuHovered || isServiceHovered) &&
                "services-submenu-visible"
              }`}
            >
              <SubMenuLink
                spy={true}
                offset={-150}
                smooth={true}
                duration={500}
                to="diagnoza"
                onClick={() => openMenu()}
              >
                <p>Electrica / Diagnoza</p>
              </SubMenuLink>
              <SubMenuLink
                spy={true}
                offset={200}
                smooth={true}
                duration={500}
                to="intretinere"
                onClick={() => openMenu()}
              >
                <p>Intretinere</p>
              </SubMenuLink>
              <SubMenuLink
                spy={true}
                offset={250}
                smooth={true}
                duration={500}
                to="mecanica"
                onClick={() => openMenu()}
              >
                <p>Mecanica auto</p>
              </SubMenuLink>
              <SubMenuLink
                spy={true}
                offset={250}
                smooth={true}
                duration={500}
                to="instalatii"
                onClick={() => openMenu()}
              >
                <p>Servicii clima</p>
              </SubMenuLink>
              <SubMenuLink
                spy={true}
                offset={150}
                smooth={true}
                duration={500}
                to="retrofit"
                onClick={() => openMenu()}
              >
                <p>Retrofitting</p>
              </SubMenuLink>
              <SubMenuLink
                spy={true}
                offset={150}
                smooth={true}
                duration={500}
                to="piese"
                onClick={() => openMenu()}
              >
                <p>Piese auto</p>
              </SubMenuLink>
            </div>
          </MenuItem>
          <MenuItem>
            <MenuLink
              spy={true}
              offset={-70}
              smooth={true}
              duration={500}
              to="process"
              onClick={() => openMenu()}
            >
              <p>Cum procedam</p>
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink
              spy={true}
              offset={-70}
              smooth={true}
              duration={500}
              to="reviews"
              onClick={() => openMenu()}
            >
              <p>Recenzii</p>
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink
              spy={true}
              offset={-70}
              smooth={true}
              duration={500}
              to="contact"
              onClick={() => openMenu()}
            >
              <p>Contact</p>
            </MenuLink>
          </MenuItem>
          <MenuItem>
            <p
              className="info-utile"
              onClick={() =>
                (function () {
                  updateModal({
                    moreInfo: moreInfo,
                    title: "Informatii utile",
                    image: infoImage,
                  });
                  handleModalVisibility();
                })()
              }
            >
              Info utile
            </p>
          </MenuItem>
        </Router>
      </MenuStyled>
    </>
  );
}
