import styled from "styled-components";

const ModalStyled = styled.article`
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  display: ${({ isModalVisible }) => (isModalVisible ? "block" : "none")};
  backdrop-filter: blur(10px);
  z-index: 10000;
  .modal-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${({ theme }) => theme.COLORS.SECONDARY_BG};
    color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
    width: 100%;
    height: 100%;

    .modal-picture {
      position: relative;
      background-image: url(${({ modalProps }) => modalProps?.image});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 30%;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    .modal-title {
      position: absolute;
      bottom: 30px;
      left: 30px;
      background-color: rgba(0, 0, 0, 0.5);
      font-size: 25px;
      padding: 10px 19px 4px;
      font-weight: bold;
      line-height: 23px;
      display: block;
    }
    .modal-text {
      overflow-y: scroll;
      padding: 30px;
      padding-bottom: 100px;
      font-size: 20px;
      position: absolute;
      height: 70%;
      line-height: 23px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
    .close-modal {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      .closeButton {
        background-color: white;
        padding: 10px;
        padding-bottom: 0;
        border-radius: 10px;
        transition: 0.3s ease;
        transform: rotate(none);
        &:hover {
          transition: 0.3s ease;
        }
        &:hover .close-icon {
          transform: rotate(180deg);
          transition: 0.5s ease;
        }
      }
    }
  }

  @media screen and (min-width: 1200px) {
    .modal-wrapper {
      background-color: ${({ theme }) => theme.COLORS.SECONDARY_BG};
      color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
      height: 80%;
      max-width: 1100px;
      .modal-text {
        height: 70%;
      }
      .modal-picture {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        height: 30%;
      }
    }
  }
`;
export default ModalStyled;
