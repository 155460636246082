export const REVIEW_DATA = [
  {
    id: 1,
    image: require("./stars.png"),
    title: "Danut",
    content:
      "Un service excelent si rapiditate in rezolvare problemelor. Ppersonalul este de nota zece. Recomand.",
  },
  {
    id: 2,
    image: require("./stars.png"),
    title: "Marian",
    content:
      "Un service de excepție, cu preturi bune și servicii de calitate. Recomand",
  },
  {
    id: 3,
    image: require("./stars.png"),
    title: "Dobrin",
    content:
      "Recomand cu incredere. Servicii deosebite, punctualitate si profesionalism",
  },
  {
    id: 4,
    image: require("./stars.png"),
    title: "Andrei",
    content: "Servicii de calitate, seriozitate și punctualitate.",
  },
];
