import React from "react";
import { NavbarStyled } from "./navbarStyled";
import Menu from "../menu/menu";

export default function Navbar({ isOpen, openMenu }) {
  return (
    <>
      <NavbarStyled isOpen={isOpen}>
        <Menu openMenu={openMenu} />
      </NavbarStyled>
    </>
  );
}
