import styled from "styled-components";
import bgImage from "./bg-image2.png";

export const HomeStyled = styled.article`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - 80px);
  background-image: url(${bgImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 80px;
  color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
  .homeTitles {
    position: relative;
    padding: 8px;
    background-color: rgba(8, 8, 8, 0.85);
    padding-right: initial;
    margin: 10% 0 0 0;
    width: 255px;
    z-index: 100;
    p {
      line-height: 150%;
      font-size: 1.11rem;
    }
    .middleText {
      line-height: 130%;
      font-size: 1.6rem;
    }
    span {
      font-weight: 700;
    }
  }
  .footerTextWrapper {
    position: relative;
    margin: 1% 0;
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 50%;
  }
  .footerText {
    position: relative;
    text-align: center;
    font-size: 3px;
    z-index: 100;
  }
  .footerRotateWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    background-image: linear-gradient(
      145deg,
      transparent,
      transparent,
      ${({ theme }) => theme.COLORS.CONTRAST_COLOR}
    );
    animation: rotate 6s infinite linear;
    -webkit-animation: rotate 6s infinite linear;
    -moz-animation: rotate 6s infinite linear;
    -ms-animation: rotate 6s infinite linear;

    .footerRotate {
      background-color: rgba(8, 8, 8, 0.95);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 98%;
      height: 98%;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
  @media screen and (min-width: 330px) {
    .homeTitles {
      padding: 20px;
    }
  }
  @media screen and (min-width: 600px) {
    .homeTitles {
      width: initial;
      padding: 40px;
      p {
        font-size: 1.4rem;
      }
      .middleText {
        font-size: 2.1rem;
      }
    }
  }
  @media screen and (min-width: 800px) {
    .homeTitles {
      margin-top: 10%;
      padding: 50px;
      p {
        font-size: 2rem;
      }
      .middleText {
        font-size: 2.7rem;
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .homeTitles {
      background-color: transparent;
      padding: 30px;
      padding-right: 50px;
      transform: translate(-150px, 5%);
      margin-bottom: 5%;
      p {
        font-size: 2.5rem;
      }
      .middleText {
        font-size: 3.8rem;
      }
    }
    .footerText {
      font-size: 14px;
    }
    .footerTextWrapper {
      position: relative;
      width: 170px;
      height: 170px;
      align-self: flex-end;
      bottom: 60px;
      right: 60px;
    }
  }
  @media screen and (min-width: 1400px) {
    .homeTitles {
      padding: 30px;
      padding-right: 120px;
      transform: translate(-150px, 0%);
      margin-bottom: 3%;
      p {
        font-size: 3.2rem;
      }
      .middleText {
        font-size: 4.5rem;
      }
    }
    .footerText {
      font-size: 14px;
    }
    .footerTextWrapper {
      position: relative;
      width: 170px;
      height: 170px;
      align-self: flex-end;
      bottom: 60px;
      right: 60px;
    }
  }
  @media screen and (min-width: 1300px) and (max-height: 800px) {
    .homeTitles {
      margin-top: 2%;
      p {
        font-size: 3rem;
      }
      .middleText {
        font-size: 4rem;
      }
    }
  }
`;
