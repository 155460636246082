import React from "react";
import StyledFooter from "./footerStyled";

import footerImage from "./footer-image.jpg";
import SAL from "./SAL.png";
import SOL from "./SOL.png";
import { useContext } from "react";
import { GDPR } from "./GDPR";

import { ModalContext } from "../../App";

export default function Footer() {
  const { updateModal, handleModalVisibility } = useContext(ModalContext);
  const title = "Termeni si conditii | GDPR";
  const image = null;
  const content = GDPR;
  const moreInfo = GDPR;

  const creditsMoreInfo = (
    <>
      <span>
        <p>
          This website uses a picture from the website freepik which has free
          license, if the attribute is made. We added the link for the picture
          here on the credits section.
        </p>
        <a href="https://www.freepik.com/free-photo/hands-mechanic-using-diagnostic-tool_1005082.htm#query=car%20diagnostic&position=22&from_view=search&track=ais">
          Image by peoplecreations
        </a>{" "}
        on Freepik
      </span>
      <p>
        <a href="https://www.flaticon.com/authors/andrea-severgnini">
          This is a car from icons-icons.com from Adrea Severgnini
        </a>
      </p>
      <p>
        Other links for the Car icon{" "}
        <a href="https://icon-icons.com/users/Hx7qByYtLyjAhU9FSsAyp/icon-sets/">
          Car icon
        </a>
      </p>
    </>
  );

  // const handleMedicalDent = () => {
  //   window.open("http://medicaldent.ro");
  // };
  return (
    <StyledFooter>
      <div className="footerWrapper">
        <div className="footer-image">
          <img src={footerImage} alt="aceasta este o masina" />
        </div>
        <div className="footer-text">
          <div className="policies">
            {/* <span onClick={handleCredits} className="clickable">
              Politici
            </span> */}
            <span
              onClick={() =>
                (function () {
                  updateModal({ title, image, content, moreInfo });
                  handleModalVisibility();
                })()
              }
            >
              Termeni si conditii | GDPR
            </span>
            <span
              onClick={() =>
                (function () {
                  updateModal({
                    creditsMoreInfo,
                  });
                  handleModalVisibility();
                })()
              }
              className="clickable"
            >
              Credits
            </span>
          </div>
          <div className="rightsReserved">
            <span>©Auto MIcar toate drepturile rezervate</span>
          </div>
        </div>
        <div className="footer-icons">
          <img src={SOL} alt="aceasta este o iconita" />
          <img src={SAL} alt="aceasta este o iconita" />
        </div>
        <div className="development">
          <a href="https://www.google.ro" target="_blank" rel="noreferrer">
            <p>by Digital Stories</p>
          </a>
        </div>
      </div>
    </StyledFooter>
  );
}
