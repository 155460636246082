import React, { useState } from "react";

import { AboutStyled } from "./aboutStyled";
import { ABOUT_TEXT_1, ABOUT_TEXT_2 } from "../../constants/constants";

import LinearColoredText from "../../components/linearColoredText/linearColoredText";
import mainImage from "./gallery-main.jpg";
import Subtitle from "../../components/subtitle/subtitle";

import {
  ABOUT_SUBTITLE,
  GALLERY_SUBTITLE,
  GALLERY_IMAGES,
} from "../../constants/constants";

import aboutImage from "./about_image.png";

export default function About() {
  const [imageSource, setImageSource] = useState(mainImage);
  const selectImage = (event) => {
    const image = event.target;
    if (image.tagName === "IMG") {
      setImageSource(image.getAttribute("src"));
    }
  };
  return (
    <AboutStyled imageSource={imageSource}>
      <LinearColoredText label="DESPRE NOI" />
      <section className="text-wrapper">
        <Subtitle subtitle={ABOUT_SUBTITLE} />
        <div className="about-text">{ABOUT_TEXT_1}</div>
        <div className="about-text_2 about-text">{ABOUT_TEXT_2}</div>
      </section>
      <img
        className="about-image"
        src={aboutImage}
        alt="Acesta este o masina"
      />

      <section className="image-gallery">
        <Subtitle subtitle={GALLERY_SUBTITLE} />

        <div className="main-image" id="mainImage"></div>

        <div className="gallery-images" onClick={(event) => selectImage(event)}>
          {GALLERY_IMAGES.map((image) => {
            return (
              <img key={image.id} src={image.image} alt="Din atelierul auto" />
            );
          })}
        </div>
      </section>
    </AboutStyled>
  );
}
