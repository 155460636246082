import styled from "styled-components";

const ServiceStyled = styled.article`
  border: 1px solid transparent;
  position: relative;
  margin-bottom: 0;
  .service-text {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 100px 0 0 0px;
    padding: 15px 0 0 15px;
    width: 100%;
    visibility: ${(props) => (props.visible ? "visible" : "hidden")};
    background-color: rgba(15, 15, 15, 0.78);

    .service-title {
      font-size: 25px;
      margin: 10px 0;
      font-weight: bold;
    }
    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      width: 50%;
      background-color: red;
      background-image: linear-gradient(
        to left,
        black,
        ${({ theme }) => theme.COLORS.CONTRAST_COLOR}
      );
    }
    &::before {
      background-image: linear-gradient(
        to top,
        black,
        ${({ theme }) => theme.COLORS.CONTRAST_COLOR}
      );
      height: 50%;
      width: 2px;
    }
    p {
      font-size: 17px;
      line-height: 27px;
      margin: 0;
      font-weight: 100;
    }
    button {
      position: relative;
      align-self: flex-start;
      font-size: 18px;
      padding: 10px 20px;
      margin-top: 30px;
      background-color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
      color: ${({ theme }) => theme.COLORS.SECONDARY_TEXT};
      border-color: transparent;
      transition: 0.3s ease;
      &:hover {
        background-color: white;
        color: black;
        transition: 0.3s ease;
        cursor: pointer;
      }
    }
  }
  .service-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: initial;
  }

  @media screen and (min-width: 390px) {
    margin-bottom: ${(props) => (props.visible ? "0" : "20%")};
  }
  @media screen and (min-width: 1250px) {
    margin-bottom: -20px;
  }

  @media screen and (min-width: 550px) {
    .service-image {
      width: 350px;
      height: 250px;
    }

    .service-text {
      margin: 80px 0 0 70px;
      padding: 30px 0 30px 30px;
      width: 450px;
      .service-title {
        margin: 0 0 10px 0;
        font-weight: bold;
      }
      p {
        font-size: 18px;
      }
    }
  }
`;

export default ServiceStyled;
