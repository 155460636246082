import styled from "styled-components";

const ReviewStyled = styled.section`
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_BG};
  color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
  .review-wrapper {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    max-width: 1500px;
  }
  .subtitle-wrapper {
    border-top: 1px solid rgba(155, 155, 155, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 0 0 0;
    max-width: 1240px;
  }
  @media screen and (min-width: 1200px) {
    .subtitle-wrapper {
      justify-content: flex-start;
      width: 100%;
      padding: 0 20px;
      max-width: 1140px;
    }
  }
`;

export default ReviewStyled;
