import React, { useEffect } from "react";
import { useState } from "react";
import { HeaderStyled } from "./headerStyled";
import Logo from "./logo/logo";
import { HamburgerMenuStyled } from "./menu/menuStyled";
import Navbar from "./navbar/navbar";
import Hamburger from "hamburger-react";

import HederContact from "../headerContact/hederContact";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const OpenMenu = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   const headerContact = document.querySelector(".navbarContactStyle");
  //   window.addEventListener("scroll", (event) => {
  //     let scroll = window.scrollY;
  //     if (scroll > 700) {
  //       headerContact.style.opacity = "1";
  //       headerContact.style.transition = "0.3s ease";
  //     } else {
  //       headerContact.style.opacity = "0";
  //       headerContact.style.transition = "0.3s ease";
  //     }
  //   });
  // });

  return (
    <>
      <HeaderStyled>
        <div className="header-wrapper">
          <div className="nav-wrapper">
            <div className="nav">
              <Logo />
              <Navbar isOpen={isOpen} openMenu={OpenMenu} />
              <HamburgerMenuStyled>
                <div className="hamburgerStyle">
                  <Hamburger
                    rounded
                    hideOutline={true}
                    size={30}
                    duration={0.6}
                    color="#DBDBDB"
                    toggled={isOpen}
                    toggle={OpenMenu}
                    className="yes"
                  />
                </div>
              </HamburgerMenuStyled>
            </div>
          </div>
          <HederContact />
        </div>
      </HeaderStyled>
    </>
  );
}
