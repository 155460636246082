import styled from "styled-components";

export const ProcessStyled = styled.div`
  color: ${({ theme }) => theme.COLORS.PRIMARY_TEXT};
  background-color: ${({ theme }) => theme.COLORS.PRIMARY_BG};
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .process-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    .left-car,
    .right-car,
    .car-good,
    .car-damaged,
    .road {
      display: none;
    }
  }
  .subtitle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 20px;
    max-width: 1140px;
  }

  @media screen and (min-width: 1200px) {
    padding: 100px 0;

    .subtitle-wrapper {
      justify-content: flex-start;
      width: 100%;
      padding: 0 20px;
      max-width: 1140px;
    }
  }
  @media screen and (min-width: 1500px) {
    .process-wrapper {
      .left-car {
        position: absolute;
        left: -75px;
        top: 35%;
        display: block;
      }
      .right-car {
        position: absolute;
        right: -105px;
        top: 34%;
        display: block;
      }
      .road {
        position: absolute;
        top: 20%;
        display: block;
      }
      .car-good {
        position: absolute;
        display: block;
        right: -94px;
        top: 25%;
      }
      .car-damaged {
        position: absolute;
        display: block;
        left: -50px;
        top: 27%;
      }
    }
  }
`;

export default ProcessStyled;
